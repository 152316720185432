

<template>
  <div class="team" v-if="mwData != {}">
    <transition name="slide">
      <table cellspacing="0" v-if="show">
        <tr>
          <th style="width:35px;">序号</th>
          <th style="width:50px;">队伍</th>
          <th style="min-width:80px;">存活</th>
          <th style="width:40px;">FK</th>
          <th style="width:60px;">FA</th>
        </tr>
        <TeamInfo :teamInfo="mwDataTemp.RED" :teamId="1" v-if="mwData.RED.players != []"></TeamInfo>
        <Team :playerInfo="item"  v-for="item in mwData.RED.players"></Team>
        <div class="line"></div>
        <TeamInfo :teamInfo="mwDataTemp.YELLOW" :teamId="2" v-if="mwData.YELLOW.players != []"></TeamInfo>
        <Team :playerInfo="item"  v-for="item in mwData.YELLOW.players"></Team>
        <div class="line"></div>
        <TeamInfo :teamInfo="mwDataTemp.BLUE" :teamId="3" v-if="mwData.BLUE.players != []"></TeamInfo>
        <Team :playerInfo="item"  v-for="item in mwData.BLUE.players"></Team>
        <div class="line"></div>
        <TeamInfo :teamInfo="mwDataTemp.GREEN"  :teamId="4" v-if="mwData.GREEN.players != []"></TeamInfo>
        <Team :playerInfo="item" v-for="item in mwData.GREEN.players"></Team>
      </table>
    </transition>

  </div>
</template>

<script>
import axios from "axios";
import TeamInfo from './components/TeamInfo/index.vue'
import Team from './components/Team/index.vue'

export default {
  components: {
    TeamInfo, Team
  },
  data() {
    return {
      show: true,
      date:"2023.1.4",
      mwData: {
        GREEN: { name: '绿队', totalFk: 0, totalFa: 0, players: [] },
        RED: {
          name: '红队',
          totalFk: 0,
          totalFa: 0,
          players: []
        },
        BLUE: { name: '蓝队', totalFk: 0, totalFa: 0, players: [] },
        YELLOW: { name: '黄队', totalFk: 0, totalFa: 0, players: [] }
      },
      mwDataTemp: {
        GREEN: { name: '绿队', totalFk: 0, totalFa: 0, players: [] },
        RED: {
          name: '红队',
          totalFk: 0,
          totalFa: 0,
          players: []
        },
        BLUE: { name: '蓝队', totalFk: 0, totalFa: 0, players: [] },
        YELLOW: { name: '黄队', totalFk: 0, totalFa: 0, players: [] }
      }


    }
  },
  methods: {
    async getMwData() {
      let { data } = await axios.get("https://webapi.domcer.com/match/getMwView")
      this.mwDataTemp=data.data
      return data.data
    },
    async setArr(arrIndex) {
      this.mwData=await this.getMwData()
      let arrs = []
      this.mwData.BLUE.players.forEach((a, i) => {
        if (arrIndex.includes(i)) {
          arrs.push(a)
        }
      })
      this.mwData.BLUE.players = arrs;
      arrs = []
      this.mwData.RED.players.forEach((a, i) => {
        if (arrIndex.includes(i)) {
          arrs.push(a)
        }
      })
      this.mwData.RED.players = arrs;
      arrs = []
      this.mwData.YELLOW.players.forEach((a, i) => {
        if (arrIndex.includes(i)) {
          arrs.push(a)
        }
      })
      this.mwData.YELLOW.players = arrs;
      arrs = []
      this.mwData.GREEN.players.forEach((a, i) => {
        if (arrIndex.includes(i)) {
          arrs.push(a)
        }
      })
      this.mwData.GREEN.players = arrs;
    }
  },
  mounted() {
    let a = 1;
    this.getMwData()
    this.setArr([0, 1, 2])
    let date=new Date();
    let year=date.getFullYear();
    let m=date.getMonth()+1;
    let day=date.getDate();
    this.date=`${year}.${m}.${day}`;
    if (a == 0) {
        a = 1
        this.setArr([0, 1, 2])
      } else {
        a = 0
        this.setArr([3, 4, 5,6])

      }
    setInterval(() => {

      if (a == 0) {
        a = 1
        this.setArr([0, 1, 2])
      } else {
        a = 0
        this.setArr([3, 4, 5,6,7,8,9])

      }
    }, 5000)
  }
}
</script>
<style lang="less">
.live_box{
  position: fixed;
  top: 27px;
  right: 23px;
  font-size: 16px;
  display: flex;
  padding: 8px 0;
  height: 28px;
  .time{
  padding: 0 6px 0 12px;
    color: #fff;
    background: #192b30;
    line-height: 28px;
  }
  .live{
    line-height: 28px;
    padding: 0 6px;
    background-image: linear-gradient(55.7deg, #eb3d3d -7.6%, #c430304a 122.2%, #b52b2b03 173.2%)
  }
}
.slide-enter-active {
  transition: all 0.5s ease-out;
}

.slide-leave-active {
  transition: all 0.5s ease-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-leave,
.slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}

@font-face {
  font-family: "PangMenZhengDao";
  src: url("https://webapi.domcer.com/font/NotoSansHans-Light.otf");
}

.line {
  height: 4px;
}
.team{
  position: fixed;
  top: 27px;
  left: 23px;
}
.team table tr:nth-child(1) th:nth-child(1),
.team table tr:nth-child(1) td {
  padding-left: 5px;
}

* {
  margin: 0;
  padding: 0;
  font-family: "PangMenZhengDao";
}

@keyframes outPut {
  0% {}
}


.team {
  text-align: left;
  animation: name duration timing-function delay iteration-count direction fill-mode;

  tr:nth-child(1) {
    background-color: #00162b;
    border: 0;
    color: #fff;
  }

  tr:nth-child(1) th {
    font-size: 14px;
    height: 42px;
  }
}



td,
th {
  height: 37px;
  text-align: center;

  &:nth-child(2) {
    padding-left: 10px;

  }

  &:nth-child(1) {
    text-align: center;
  }
}

td {
  background: #ffffffb3;
  height: 32px;
  font-size: 13px;
}

th {
  font-size: 14px;
  font-weight: 400;
}

.teaminfo {
  background-color: #ffffff;
  margin-bottom: 5px;

  th:nth-child(1) {
    border-right: 1px solid #000;
    text-align: center;
  }
}
</style>
