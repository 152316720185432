<template>
    <tr :class="{alive:!playerInfo.alive}">
        <td v-if="!playerInfo.alive">
            <svg t="1680281077191" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1934" width="10" height="10"><path d="M1024 80.497778L943.502222 0 512 431.502222 80.497778 0 0 80.497778 431.502222 512 0 943.502222 80.497778 1024 512 592.497778 943.502222 1024 1024 943.502222 592.497778 512 1024 80.497778z" p-id="1935" fill="#d81e06"></path></svg>
        </td>
        <td v-else><svg t="1680281201272" class="icon" viewBox="0 0 1397 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2184" width="10" height="10"><path d="M484.235116 1024L0 539.376564l110.282897-110.282897 373.952219 373.952218L1287.669321 0 1397.952218 110.282897 484.235116 1024z" p-id="2185" fill="#2aa515"></path></svg></td>
        <td>{{playerInfo.name}}</td>
        <td>{{playerInfo.kit}}</td>
        <td>{{playerInfo.fk}}</td>
        <td>{{playerInfo.fa}}</td>
    </tr>
</template>
<script>
export default {
    components: {
      
    },
    props: ['playerInfo'],
    data() {
        return {
            
        }
    },
    methods: {
       
    },
    mounted() {

    }
}
</script>
<style>
.alive{
    color: rgb(166,166,166);
}
</style>