<template>
    <th>
        {{get(getCunHuo) }}
    </th>
</template>
<script>
export default {
    components: {
      
    },
    props: ['getCunHuo'],
    data() {
        return {
            a: {}
        }
    },
    methods: {
        get(val) {
            let a = 0;
            val.forEach((item, index) => {
                if (item.alive == true) {
                    a++
                }
            })
            return a;
        }
    },
    mounted() {

    }
}
</script>