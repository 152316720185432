<template>
    <!-- <slot> -->
    <tr class="teaminfo">
        <th>{{teamId}}</th>
        <th :style="{color:teamColor(teamInfo.name)}">{{teamInfo.name }}</th>
        <getCunHuo :getCunHuo="teamInfo.players"></getCunHuo>
        <th>{{ teamInfo.totalFk }}</th>
        <th>{{ teamInfo.totalFa }}</th>
        <div class="line"></div>
    </tr>
    <!-- <Team :playerInfo="item" v-for="item in teamInfo.players"></Team> -->
    <!-- </slot> -->
</template>
<script>

import Team from "../Team/index.vue"
import getCunHuo from "../getCunHuo/index.vue"

export default {
    components: {
        Team, getCunHuo
    },
    filters: {
        
    },
    props: ['teamInfo','teamId'],
    data() {
        return {
            mwData: {}
        }
    },
    methods: {
        teamColor(color) {
            switch (color) {
                case "红队":
                    return "RED"
                case "蓝队":
                    return "BLUE"
                case "黄队":
                    return "#FFAA00"
                case "绿队":
                    return "GREEN"
            }
        }
    },
    mounted() {
        console.log(this.teamInfo);
    }
}
</script>